import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { Container, Row, Col } from 'react-bootstrap'
import Layout from '../components/layout'
import Banner from '../components/banner'
import Sidebar from '../components/sidebar'
import Seo from '../components/seo'

import SeoImage from '../assets/images/promo-botox.jpg'

class BotoxCaldwell extends React.Component {
    render() {
        return (
            <StaticQuery
            query={graphql`
                query BotoxCaldwellMeta {
                    site {
                        siteMetadata {
                            title
                            description
                            emailUrl
                            phone
                            google {
                                googleMapAPIKey
                                googleReCaptchaKey
                            }
                        }
                    }
                }
            `}
            render={data => (
                <>
                <Layout>
                    <Seo title={`Botox Caldwell, NJ | ${data.site.siteMetadata.title}`} description={`Dr. Sambataro, your expert botox doctor in Caldwell, uses the most up-to-date, effective botox techniques and knowledge in order to provide a cosmetic solution that will restore the confidence that comes with your aesthetic goals.`} image={SeoImage} keywords={[``]} />
                    <Banner title='Botox Caldwell, NJ' subTitle='' titleColor='#FFFFFF' subTitleColor='#FFFFFF' image={SeoImage} />
                    <section>
                        <Container>
                            <Row>
                                <Col xs='12' sm='8' md='8' lg='8'>
                                    <p class='introduction'>While Botox in the past carried a negative stigma, today it has become one of the most common and accepted aesthetic practices. Since 2019, over 8 million individuals each year receive botox in order to reach their aesthetic goals, helping to minimize the appearance of fine lines and wrinkles, and prevent future ones from appearing.</p>

                                    <p>Botox uses safe injections of botulinum toxin, or botox, to combat the effects of aging on the skin. Dr. Sambataro, your expert botox doctor in Caldwell, uses the most up-to-date, effective botox techniques and knowledge in order to provide a cosmetic solution that will restore the confidence that comes with your aesthetic goals. Those in the Caldwell area are even able to receive botox treatments at home!</p>
                                    
                                    <h2>Botox: what is it used for?</h2>

                                    <p>Botox is most commonly used in the face and neck in order to combat the negative effects that come with aging. Botox can be used to minimize the appearance of crow’s feet, laugh lines, and forehead wrinkles. Caldwell botox with Dr. Sambataro will leave you with a more youthful appearance, and an increased confidence.</p>

                                    <p>Preventative botox is also an effective solution for ensuring that fine lines and wrinkles appear significantly more slowly than they would without the procedure. The immobilization of the muscles at botox injection sites means that normal creasing and wrinkles brought on by muscular movement and facial expressions will be much less significant.</p>

                                    <p>There are also other uses for botox that you may not expect. Botox is often used to treat medical issues such as chronic migraines, excessive underarm sweating, uncontrollable blinking, and involuntary muscle spasms.</p>

                                    <h2>Botox: how does it work?</h2>

                                    <p>A botox procedure works by injecting the solution into the desired areas, immobilizing the muscle to minimize fine lines, wrinkles, and creases in the face.</p>

                                    <p>The procedure is quick, and only takes a few minutes from start to finish. Slight variations in time would obviously occur depending on the number of injection sites for the patient. No anesthesia is required. Instead, most doctors will opt for a numbing topical cream to prevent unnecessary discomfort.</p>

                                    <p>Botox is not painful, and is generally comparable to a mild bee sting at worst. Most agree that it is less painful than a typical shot received at the doctor’s office.</p>

                                    <h2>Botox: results and recovery timeline</h2>

                                    <p>The first 24 hours provide the most important stage of recovery for botox patients. It is important to resist any rubbing of the injection site, as the botox solution could potentially spread into unwanted surrounding regions.</p>

                                    <p>Your first night’s sleep you will want to sleep on your back and slightly elevated. Make sure you don’t rub or pin your face against pillows. You will also want to avoid high-intensity exercise for a few days after your procedure. Allow your body to recover for a couple days.</p>

                                    <p>Your botox will take between 7 and 14 days to fully achieve the desired results. After 14 days you will have a final check-in with your Caldwell doctor to confirm that everything went as planned. Once the check-in is complete you are free to show off your new, aesthetic, youthful look.</p>

                                    <h2>Botox: side effects</h2>

                                    <p>You have undoubtedly been exposed, either through media or anecdotal experience, to someone who has had negative side effects from a “botched” botox procedure. Rest assured, botox has come a long way, and with a reputable Caldwell doctor like Dr. Sambataro, side effects and risks are greatly minimized.</p>

                                    <p>Side effects could include:</p>

                                    <ul>
                                        <li>muscle weakness</li>
                                        <li>numbness</li>
                                        <li>drooping eyelid or smile</li>
                                        <li>excessive inflammation</li>
                                        <li>excessive bruising</li>
                                        <li>chronic headaches</li>
                                    </ul>

                                    <p>Most side effects that do occur should be gone within 24-48 hours of the procedure. However, once again, with a reputable doctor like Dr. Sambataro who is up to date with botox procedures and has countless successful procedures on his resume, side effects and risk are greatly minimized.</p>

                                    <h2>Botox: an in-home solution</h2>

                                    <p>Dr. Sambataro offers Caldwell residents an in-home solution for those that want to get botox but either have exceedingly busy schedules, or simply prefer the comfort of their own home for the procedure.</p>

                                    <h2>Botox: next step</h2>

                                    <p>If you feel that botox could be a solution for your aesthetic needs, schedule a consultation with Optimal Health and Wellness today. We can go over the procedure, the risks, and any other questions you may have. Let’s start the process to get you to your aesthetic goal today!</p>
                                </Col>
                                <Sidebar title={data.site.siteMetadata.title} phone={data.site.siteMetadata.phone} email={data.site.siteMetadata.emailUrl} hasCare={true} hasContact={true} />
                            </Row>
                        </Container>
                    </section>
                </Layout>
                </>
            )}
            />
        )
    }
}

export default BotoxCaldwell